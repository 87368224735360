var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-card',{attrs:{"title":"Novo Vendedor"}},[_c('b-form',{on:{"submit":function($event){$event.preventDefault();}}},[_c('validation-observer',{ref:"form"},[_c('b-row',[_c('b-col',{attrs:{"md":"3"}},[_c('b-form-group',{attrs:{"label":"Código","label-for":"h-codigo"}},[_c('validation-provider',{attrs:{"name":"Código","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"h-codigo","placeholder":"Código","maxlength":"3"},model:{value:(_vm.vendedor.codigo),callback:function ($$v) {_vm.$set(_vm.vendedor, "codigo", $$v)},expression:"vendedor.codigo"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Nome","label-for":"h-nome"}},[_c('validation-provider',{attrs:{"name":"Nome","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"h-nome","placeholder":"Nome","maxlength":"50"},model:{value:(_vm.vendedor.nome),callback:function ($$v) {_vm.$set(_vm.vendedor, "nome", $$v)},expression:"vendedor.nome"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"3"}},[_c('b-form-group',{attrs:{"label":"Telemovel","label-for":"h-telemovel"}},[_c('validation-provider',{attrs:{"name":"Telemovel","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"h-telemovel","placeholder":"Telemovel","maxlength":"20"},model:{value:(_vm.vendedor.telemovel),callback:function ($$v) {_vm.$set(_vm.vendedor, "telemovel", $$v)},expression:"vendedor.telemovel"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1),_c('b-row',[_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label":"Email","label-for":"h-email"}},[_c('validation-provider',{attrs:{"name":"Email","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"h-email","placeholder":"Email","maxlength":"256"},model:{value:(_vm.vendedor.email),callback:function ($$v) {_vm.$set(_vm.vendedor, "email", $$v)},expression:"vendedor.email"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1),_c('b-row',[_c('b-col',[_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(255, 255, 255, 0.15)'),expression:"'rgba(255, 255, 255, 0.15)'",modifiers:{"400":true}}],staticClass:"mr-1",attrs:{"type":"submit","variant":"primary"},on:{"click":function($event){return _vm.switchLoc()}}},[_vm._v(" Guardar ")]),_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(186, 191, 199, 0.15)'),expression:"'rgba(186, 191, 199, 0.15)'",modifiers:{"400":true}}],attrs:{"type":"reset","variant":"outline-secondary"}},[_vm._v(" Cancelar ")])],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }