<template>
  <b-card title="Novo Vendedor">
    <b-form @submit.prevent>
      <validation-observer ref="form">
        <b-row>
          <!-- Código -->
          <b-col md="3">
            <b-form-group
              label="Código"
              label-for="h-codigo"
            >
              <validation-provider
                #default="{ errors }"
                name="Código"
                rules="required"
              >
                <b-form-input
                  id="h-codigo"
                  v-model="vendedor.codigo"
                  placeholder="Código"
                  maxlength="3"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <!-- Empresa -->
          <b-col md="6">
            <b-form-group
              label="Nome"
              label-for="h-nome"
            >
              <validation-provider
                #default="{ errors }"
                name="Nome"
                rules="required"
              >
                <b-form-input
                  id="h-nome"
                  v-model="vendedor.nome"
                  placeholder="Nome"
                  maxlength="50"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <!-- Empresa -->
          <b-col md="3">
            <b-form-group
              label="Telemovel"
              label-for="h-telemovel"
            >
              <validation-provider
                #default="{ errors }"
                name="Telemovel"
                rules="required"
              >
                <b-form-input
                  id="h-telemovel"
                  v-model="vendedor.telemovel"
                  placeholder="Telemovel"
                  maxlength="20"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <!-- Código -->
          <b-col md="12">
            <b-form-group
              label="Email"
              label-for="h-email"
            >
              <validation-provider
                #default="{ errors }"
                name="Email"
                rules="required"
              >
                <b-form-input
                  id="h-email"
                  v-model="vendedor.email"
                  placeholder="Email"
                  maxlength="256"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>
        <!-- submit and reset -->
        <b-row>
          <b-col>
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              type="submit"
              variant="primary"
              class="mr-1"
              @click="switchLoc()"
            >
              Guardar
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="reset"
              variant="outline-secondary"
            >
              Cancelar
            </b-button>
          </b-col>
        </b-row>
      </validation-observer>
    </b-form>
  </b-card>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BForm,
  BButton,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import axios from 'axios'
import { ValidationProvider, ValidationObserver, localize } from 'vee-validate'
import { required } from '@validations'
import pt from 'vee-validate/dist/locale/pt_PT'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    BButton,
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      required,
      vendedor: {
        codigo: '',
        nome: '',
        email: '',
        telemovel: '',
      },
    }
  },
  created() {
    localize('pt', pt)
  },
  methods: {
    switchLoc() {
      this.$refs.form.validate().then(valid => {
        if (!valid) {
          this.erroGravacao('danger', 'CheckIcon')
        } else {
          this.guardarVendedor()
        }
      })
    },
    guardarVendedor() {
      axios.post('/api/v1/Vendedores', this.vendedor)
        .then(this.showToast('success', 'CheckIcon'))
    },

    showToast(variant, icon) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: 'Sucesso',
          icon,
          text: 'Registo gravado com sucesso !',
          variant,
        },

      })
    },
    erroGravacao(variant, icon) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: 'Erro',
          icon,
          text: 'O Registo apresenta erros !',
          variant,
        },

      })
    },

  },
}
</script>
